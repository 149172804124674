<template>
  <div class="evaluationBox">
    <div v-if="!evToEmail">
      <!-- 评价设置 -->
      <div class="evtittle">
        <span>{{ $t("font-evaluate-module-v1-evaluation-set") }}</span>
        <el-select
          popper-class="evSelect"
          v-model="evValue"
          :placeholder="$t('label.tabpage.pleaseSelectz')"
          @change="switchSet"
        >
          <el-option
            v-for="item in evOptions"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 评价中 新建 编辑 -->
      <div class="evContent">
        <!-- 评价问题 -->
        <div class="evProblem">
          <div class="problemText">
            {{ $t("font-evaluate-module-v1-evquestions") }}
          </div>
          <el-input
            type="textarea"
            :rows="1"
            placeholder=""
            v-model="evProblemText"
          >
          </el-input>
        </div>
        <!-- 评价详情 -->
        <div class="evDetailBox">
          <div class="detailText">
            {{ $t("font-evaluate-module-v1-evdetails") }}
          </div>
          <!-- 好 中 差评 详情 -->
          <div class="evDetail">
            <!-- 好评 -->
            <div class="goodReputation reputation">
              <div
                class="goodTop reputationTop"
                @mouseover="goodMouseover"
                @mouseout="goodMouseout"
              >
                <svg class="icon" aria-hidden="true" v-show="isEvTypeOne">
                  <use href="#icon-full_unsel_facce1_good"></use>
                </svg>
                <svg class="icon" aria-hidden="true" v-show="isEvTypeTwo">
                  <use href="#icon-full_unsel_hand_good"></use>
                </svg>
                <svg class="icon" aria-hidden="true" v-show="isEvTypeThree">
                  <use href="#icon-full_unsel_facce2_good"></use>
                </svg>
                <div class="haoping pingjia">{{ evGoodValue }}</div>
                <!-- 编辑 按钮 -->
                <div
                  class="editBtnGood"
                  @click="goodEditMethod"
                  v-show="goodIsEdit"
                >
                  <svg class="icon" aria-hidden="true">
                    <use href="#icon-assess_edit_cli"></use>
                  </svg>
                </div>
                <!-- 编辑 弹框 -->
                <div class="goodEditDetail allEditDetail" v-show="goodVisible">
                  <!-- 三角 -->
                  <div class="triangle"></div>
                  <!-- 选择图片 -->
                  <div class="chooseImage">
                    <div>{{ $t("font-evaluate-module-v1-select-img") }}</div>
                    <div class="threeImage">
                      <!-- 类型一 图片 -->
                      <div class="imageOne" @click="goodimageOneMethod">
                        <svg
                          class="icon"
                          aria-hidden="true"
                          v-show="!goodisCheckedIconOne"
                        >
                          <use href="#icon-full_unsel_facce1_good"></use>
                        </svg>
                        <svg
                          class="icon checkedIcon"
                          aria-hidden="true"
                          v-show="goodisCheckedIconOne"
                        >
                          <use href="#icon-full_sel_facce1_good"></use>
                        </svg>
                      </div>
                      <!-- 类型二 图片 -->
                      <div class="imageTwo" @click="goodimageTwoMethod">
                        <svg
                          class="icon"
                          aria-hidden="true"
                          v-show="!goodisCheckedIconTwo"
                        >
                          <use href="#icon-full_unsel_hand_good"></use>
                        </svg>
                        <svg
                          class="icon checkedIcon"
                          aria-hidden="true"
                          v-show="goodisCheckedIconTwo"
                        >
                          <use href="#icon-full_sel_hand_good"></use>
                        </svg>
                      </div>
                      <!-- 类型三 图片 -->
                      <div class="imageThree" @click="goodimageThreeMethod">
                        <svg
                          class="icon"
                          aria-hidden="true"
                          v-show="!goodisCheckedIconThree"
                        >
                          <use href="#icon-full_unsel_facce2_good"></use>
                        </svg>
                        <svg
                          class="icon checkedIcon"
                          aria-hidden="true"
                          v-show="goodisCheckedIconThree"
                        >
                          <use href="#icon-full_sel_facce2_good"></use>
                        </svg>
                      </div>
                    </div>
                    <!-- 文件应小于500KB -->
                    <div>{{ $t("font-evaluate-module-v1-smaller-500") }}</div>
                  </div>
                  <!-- 评价 -->
                  <div class="appraise">
                    <div>{{ $t("font-evaluate-module-v1-evaluation") }}</div>
                    <div>
                      <el-input
                        type="textarea"
                        :rows="1"
                        placeholder=""
                        v-model="evGoodText"
                      >
                      </el-input>
                    </div>
                    <!-- 评价之后显示的回复： -->
                    <div>{{ $t("font-evaluate-module-v1-response") }}</div>
                    <div>
                      <el-input
                        type="textarea"
                        :rows="1"
                        placeholder=""
                        v-model="evGoodReplay"
                      >
                      </el-input>
                    </div>
                  </div>
                  <!-- 取消 保存 -->
                  <div class="goodEditBtn allEditBtn">
                    <!-- 取消 -->
                    <el-button
                      plain
                      class="goodcancleBtn"
                      @click="goodcancleBtn"
                      >{{ $t("label.emailtocloudcc.button.back") }}</el-button
                    >
                    <!-- 保存 -->
                    <el-button
                      type="primary"
                      plain
                      class="goodsaveBtn allSaveBtn"
                      @click="goodsaveMeth"
                      >{{ $t("label.chatter.save") }}</el-button
                    >
                  </div>
                </div>
              </div>

              <!-- 评价之后显示的回复 -->
              <div class="goodText reputationText">
                <p>{{ $t("font-evaluate-module-v1-response") }}</p>
                <div class="goodValue reputationValue">{{ goodValue }}</div>
              </div>
            </div>
            <!-- 中评 -->
            <div
              class="reputation"
              :class="isForbidden ? 'centerReputation' : 'forbiddenClass'"
            >
              <div
                class="centerTop reputationTop"
                @mouseover="centerMouseover"
                @mouseout="centerMouseout"
              >
                <svg class="icon" aria-hidden="true" v-show="isEvTypeOne">
                  <use href="#icon-full_unsel_facce1_commonly"></use>
                </svg>
                <svg class="icon" aria-hidden="true" v-show="isEvTypeTwo">
                  <use href="#icon-full_unsel_hand_commonly"></use>
                </svg>
                <svg class="icon" aria-hidden="true" v-show="isEvTypeThree">
                  <use href="#icon-full_unsel_facce2_commonly"></use>
                </svg>
                <div class="zhongping pingjia">{{ evCenterValue }}</div>
                <div class="editAndForbidden" v-show="centerIsEdit">
                  <!-- 编辑 按钮 -->
                  <div
                    class="editBtnCenter"
                    @click="centerEditMethod"
                    v-show="centerishowBtn"
                  >
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-assess_edit_cli"></use>
                    </svg>
                  </div>
                  <!-- 禁用 按钮 -->
                  <div
                    class="forbiddenBtnCenter"
                    @click="centerforbiddenMethod"
                    v-show="centerishowBtn"
                  >
                    <svg class="icon" aria-hidden="true">
                      <use fill="#333333" href="#icon-assess_disable_cli"></use>
                    </svg>
                  </div>
                  <!-- 启用 按钮 -->
                  <div
                    class="startBtnCenter"
                    @click="centerstartMethod"
                    v-show="!centerishowBtn"
                  >
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-assess_enable_cli"></use>
                    </svg>
                  </div>
                </div>

                <!-- 编辑 弹框 -->
                <div
                  class="goodEditDetail allEditDetail"
                  v-show="centerVisible"
                >
                  <!-- 三角 -->
                  <div class="triangle"></div>
                  <!-- 选择图片 -->
                  <div class="chooseImage">
                    <div>{{ $t("label.emailtocloudcc.button.back") }}</div>
                    <div class="threeImage">
                      <!-- 类型一 图片 -->
                      <div class="imageOne" @click="centerimageOneMethod">
                        <svg
                          class="icon"
                          aria-hidden="true"
                          v-show="!centerisCheckedIconOne"
                        >
                          <use href="#icon-full_unsel_facce1_commonly"></use>
                        </svg>
                        <svg
                          class="icon checkedIcon"
                          aria-hidden="true"
                          v-show="centerisCheckedIconOne"
                        >
                          <use href="#icon-full_sel_facce1_commonly"></use>
                        </svg>
                      </div>
                      <!-- 类型二 图片 -->
                      <div class="imageTwo" @click="centerimageTwoMethod">
                        <svg
                          class="icon"
                          aria-hidden="true"
                          v-show="!centerisCheckedIconTwo"
                        >
                          <use href="#icon-full_unsel_hand_commonly"></use>
                        </svg>
                        <svg
                          class="icon checkedIcon"
                          aria-hidden="true"
                          v-show="centerisCheckedIconTwo"
                        >
                          <use href="#icon-full_sel_hand_commonly"></use>
                        </svg>
                      </div>
                      <!-- 类型三 图片 -->
                      <div class="imageThree" @click="centerimageThreeMethod">
                        <svg
                          class="icon"
                          aria-hidden="true"
                          v-show="!centerisCheckedIconThree"
                        >
                          <use href="#icon-full_unsel_facce2_commonly"></use>
                        </svg>
                        <svg
                          class="icon checkedIcon"
                          aria-hidden="true"
                          v-show="centerisCheckedIconThree"
                        >
                          <use href="#icon-full_sel_facce2_commonly"></use>
                        </svg>
                      </div>
                    </div>
                    <!-- 文件应小于500KB -->
                    <div>{{ $t("font-evaluate-module-v1-smaller-500") }}</div>
                  </div>
                  <!-- 评价 -->
                  <div class="appraise">
                    <div>{{ $t("font-evaluate-module-v1-evaluation") }}</div>
                    <div>
                      <el-input
                        type="textarea"
                        :rows="1"
                        placeholder=""
                        v-model="evCenterText"
                      >
                      </el-input>
                    </div>
                    <!-- 评价之后显示的回复： -->
                    <div>{{ $t("font-evaluate-module-v1-response") }}</div>
                    <div>
                      <el-input
                        type="textarea"
                        :rows="1"
                        placeholder=""
                        v-model="evCenterReplay"
                      >
                      </el-input>
                    </div>
                  </div>
                  <!-- 取消 保存 -->
                  <div class="centerEditBtn allEditBtn">
                    <!-- 取消 -->
                    <el-button
                      plain
                      class="centercancleBtn"
                      @click="centercancleBtn"
                      >{{ $t("label.emailtocloudcc.button.back") }}</el-button
                    >
                    <!-- 保存 -->
                    <el-button
                      type="primary"
                      plain
                      class="centersaveBtn allSaveBtn"
                      @click="centersaveMeth"
                      >{{ $t("label.chatter.save") }}</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="centerText reputationText">
                <!-- 评价之后显示的回复： -->
                <p>{{ $t("font-evaluate-module-v1-response") }}</p>
                <div class="centerValue reputationValue">{{ centerValue }}</div>
              </div>
            </div>
            <!-- 差评 -->
            <div class="noReputation reputation">
              <div
                class="noTop reputationTop"
                @mouseover="noMouseover"
                @mouseout="noMouseout"
              >
                <svg class="icon" aria-hidden="true" v-show="isEvTypeOne">
                  <use href="#icon-full_unsel_facce1_bad"></use>
                </svg>
                <svg class="icon" aria-hidden="true" v-show="isEvTypeTwo">
                  <use href="#icon-full_unsel_hand_bad"></use>
                </svg>
                <svg class="icon" aria-hidden="true" v-show="isEvTypeThree">
                  <use href="#icon-full_unsel_facce2_bad"></use>
                </svg>
                <div class="chaping pingjia">{{ evNoValue }}</div>
                <!-- 编辑小手 -->
                <div class="editBtnNo" @click="noEditMethod" v-show="noIsEdit">
                  <svg class="icon" aria-hidden="true">
                    <use href="#icon-assess_edit_cli"></use>
                  </svg>
                </div>
                <!-- 编辑弹框 -->
                <div class="noEditDetail allEditDetail" v-show="noVisible">
                  <!-- 三角 -->
                  <div class="triangle"></div>
                  <!-- 选择图片 -->
                  <div class="chooseImage">
                    <div>{{ $t("font-evaluate-module-v1-select-img") }}</div>
                    <div class="threeImage">
                      <!-- 类型一 图片 -->
                      <div class="imageOne" @click="noimageOneMethod">
                        <svg
                          class="icon"
                          aria-hidden="true"
                          v-show="!noisCheckedIconOne"
                        >
                          <use href="#icon-full_unsel_facce1_bad"></use>
                        </svg>
                        <svg
                          class="icon checkedIcon"
                          aria-hidden="true"
                          v-show="noisCheckedIconOne"
                        >
                          <use href="#icon-full_sel_facce1_bad"></use>
                        </svg>
                      </div>
                      <!-- 类型二 图片 -->
                      <div class="imageTwo" @click="noimageTwoMethod">
                        <svg
                          class="icon"
                          aria-hidden="true"
                          v-show="!noisCheckedIconTwo"
                        >
                          <use href="#icon-full_unsel_hand_bad"></use>
                        </svg>
                        <svg
                          class="icon checkedIcon"
                          aria-hidden="true"
                          v-show="noisCheckedIconTwo"
                        >
                          <use href="#icon-full_sel_hand_bad"></use>
                        </svg>
                      </div>
                      <!-- 类型三 图片 -->
                      <div class="imageThree" @click="noimageThreeMethod">
                        <svg
                          class="icon"
                          aria-hidden="true"
                          v-show="!noisCheckedIconThree"
                        >
                          <use href="#icon-full_unsel_facce2_bad"></use>
                        </svg>
                        <svg
                          class="icon checkedIcon"
                          aria-hidden="true"
                          v-show="noisCheckedIconThree"
                        >
                          <use href="#icon-full_sel_facce2_bad"></use>
                        </svg>
                      </div>
                    </div>
                    <!-- 文件应小于500KB -->
                    <div>{{ $t("font-evaluate-module-v1-smaller-500") }}</div>
                  </div>
                  <!-- 评价 -->
                  <div class="appraise">
                    <div>{{ $t("font-evaluate-module-v1-evaluation") }}</div>
                    <div>
                      <el-input
                        type="textarea"
                        :rows="1"
                        placeholder=""
                        v-model="evNoText"
                      >
                      </el-input>
                    </div>
                    <!-- 评价之后显示的回复： -->
                    <div>{{ $t("font-evaluate-module-v1-response") }}</div>
                    <div>
                      <el-input
                        type="textarea"
                        :rows="1"
                        placeholder=""
                        v-model="evNoReplay"
                      >
                      </el-input>
                    </div>
                  </div>
                  <!-- 取消 保存 -->
                  <div class="noEditBtn allEditBtn">
                    <!-- 取消 -->
                    <el-button plain class="nocancleBtn" @click="nocancleBtn">{{
                      $t("label.emailtocloudcc.button.back")
                    }}</el-button>
                    <!-- 保存 -->
                    <el-button
                      type="primary"
                      plain
                      class="nosaveBtn allSaveBtn"
                      @click="nosaveMeth"
                      >{{ $t("label.chatter.save") }}</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="noText reputationText">
                <!-- 评价之后显示的回复： -->
                <p>{{ $t("font-evaluate-module-v1-response") }}</p>
                <div class="noValue reputationValue">{{ noValue }}</div>
              </div>
            </div>
            <!-- 好中差评得分标准 -->
            <div class="suggests">
              <svg
                class="icon"
                aria-hidden="true"
                @mouseover="showSuggest"
                @mouseout="closeSuggest"
              >
                <use href="#icon-helpp-blue"></use>
              </svg>
              <!-- 提示 -->
              <div class="suggContent" v-show="isshowSugg">
                <!-- 好评得分:10分 -->
                <div class="goodsugg">
                  {{ $t("font-evaluate-module-v1-ten-points") }}
                </div>
                <!-- 一般得分:5分 -->
                <div class="centersugg">
                  {{ $t("font-evaluate-module-v1-five-points") }}
                </div>
                <!-- 差评得分:1分 -->
                <div class="nosugg">
                  {{ $t("font-evaluate-module-v1-one-points") }}
                </div>
              </div>
              <!-- 提示的三角 -->
              <div class="suggtriangle" v-show="isshowSugg"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 评价完成 -->
      <div class="evBottom">
        <!-- 添加评价链接： -->
        <div class="evLinkv chooseBox" v-show="isshowEmail">
          <!-- 添加评价链接: -->
          <span>{{ $t("font-evaluate-module-v1-evaluation-link") }}</span>
          <el-select
            v-model="evLinkValue"
            placeholder=""
            @change="caseOpenMethod"
          >
            <el-option
              v-for="item in evLinkOptions"
              :key="item.id"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- 自定义模板 -->
          <span class="casebox" v-show="isCaseClosed" @click="goToEmail">{{
            $t("font-evaluate-module-v1-template")
          }}</span>
        </div>
        <!-- 重新打开个案： -->
        <div class="evCase chooseBox">
          <span>{{ $t("font-evaluate-module-v1-open-case") }}</span>
          <el-select v-model="evCaseValue" placeholder="">
            <el-option
              v-for="item in evCaseOptions"
              :key="item.id"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 通知个案所有者： -->
        <div class="evCaseOwner chooseBox">
          <span>{{ $t("font-evaluate-module-v1-case-owners") }}</span>
          <el-select v-model="evCaseOwnerValue" placeholder="">
            <el-option
              v-for="item in evCaseOwnerOptions"
              :key="item.id"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 收到差评时,通知以下用户 -->
        <div class="evSomeone chooseBox">
          <span>{{ $t("font-evaluate-module-v1-following-peopple") }}</span>
          <el-select
            v-model="evSomeoneValue"
            multiple
            filterable
            :placeholder="$t('font-evaluate-module-v1-select-person')"
          >
            <el-option
              v-for="item in evSomeoneOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 取消 保存 -->
      <div class="operationBox">
        <!-- 取消 -->
        <el-button plain class="cancleBtn" @click="cancleBtn">{{
          $t("label.emailtocloudcc.button.back")
        }}</el-button>
        <!-- 保存 -->
        <el-button type="primary" plain class="saveBtn" @click="saveMeth">{{
          $t("label.chatter.save")
        }}</el-button>
      </div>
    </div>

    <!-- 一般评价确认禁用  弹窗-->
    <!-- 告警 -->
    <el-dialog
      :visible.sync="deleteRuleTo"
      :title="$t('font-evaluate-module-v1-emergency')"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="dialog-body">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-danger"></use>
        </svg>
        <!-- 您确定要禁用吗? -->
        <div>{{ $t("font-evaluate-module-v1-disable") }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="concelForbidden">{{
          $t("button_partner_cancel")
        }}</el-button>
        <el-button type="primary" @click="confirmForbidden">{{
          $t("label.ems.confirm")
        }}</el-button>
      </span>
    </el-dialog>

    <!-- 邮件模板组件 -->
    <emailTemplate
      ref="evEmail"
      :judgeType="textOrHtml"
      :headerTemp="templeteName"
      :editTemInfo="editTemInfo"
      :evToeditTem="evToeditTem"
      @goBackToEv="goBackToEv"
      v-show="evToEmail"
    ></emailTemplate>
  </div>
</template>
<script>
import * as evaluationApi from "./api.js";
import emailTemplate from "@/views/systemSettings/components/communicationTemplate/components/EmailComponents/Templete/newTemplete.vue";
export default {
  name: "evaluationSetting",
  components: { emailTemplate },
  data() {
    return {
      evToeditTem: "evluation",
      textOrHtml: "2",
      templeteName: "edit",
      editTemInfo: {},
      evToEmail: false,
      isshowEmail: true, //邮件评价设置 才有 添加评价链接这一下拉选择项
      evValue: "", //评价设置类型下拉选择数据
      evProblemText: "", //评价问题
      evOptions: [
        {
          id: "evaluationsetting001",
          value: this.$i18n.t(
            "font-evaluate-module-v1-email-evaluation-settings"
          ), //邮件评价设置
        },
        {
          //评价设置类型
          id: "evaluationsetting002",
          value: this.$i18n.t(
            "font-evaluate-module-v1-web-page-evaluation-settings"
          ), //网页评价设置
        },
        {
          id: "evaluationsetting003",
          value: this.$i18n.t(
            "font-evaluate-module-v1-small-program-evaluation-settings"
          ), //小程序评价设置
        },
      ],
      isshowSugg: false, //评价得分提示
      evaluationSetData: {}, //评价设置页的 数据
      evDetailArray: [], //好 中 差评详情 数据回显
      // 好评
      evGoodValue: "", // 好评文本
      goodValue: "", //好评 评价后的回复
      evGoodText: this.$i18n.t("label.evaluation.praise"), //好评编辑中的  评价     好评
      evGoodReplay: this.$i18n.t("font-evaluate-module-v1-continue-work"), //好评编辑中的  评价回复    您的评价激励了我们！我们会继续努力！
      goodVisible: false, //好评编辑 弹框的显示隐藏
      goodIsEdit: false, //好评 悬浮显示编辑 小手
      goodDetailId: "", //好评 详情id
      goodisCheckedIconOne: true, //好评 选中第一种样式类型
      goodisCheckedIconTwo: false, //好评 选中第二种样式类型
      goodisCheckedIconThree: false, //好评 选中第三种样式类型
      // 中评
      evCenterValue: "", //中评文本
      centerValue: "", //中评 评价后的回复
      evCenterText: this.$i18n.t("label.evaluation.general"), //中评编辑中的  评价    一般
      evCenterReplay: this.$i18n.t("font-evaluate-module-v1-more-satisfied"), //中评编辑中的  评价回复    我们做的还不够好！下次一定会让您更加满意
      centerVisible: false, //中评编辑 弹框的显示隐藏
      centerIsEdit: false, //中评 悬浮显示编辑 小手
      centerisCheckedIconOne: true, //一般 选中第一种样式类型
      centerisCheckedIconTwo: false, //一般 选中第二种样式类型
      centerisCheckedIconThree: false, //一般 选中第三种样式类型
      centerDetailId: "", //中评 详情id
      deleteRuleTo: false, //一般评价禁用弹窗
      isForbidden: true, //控制 中评禁用，启用的样式
      centerishowBtn: true, //控制 中评编辑，禁用，启用的显示和隐藏
      // 差评
      evNoValue: "", //差评文本
      noValue: "", //差评 评价后的回复
      evNoText: this.$i18n.t("lable.evaluation.badreview"), //差评编辑中的  评价    差评
      evNoReplay: this.$i18n.t("font-evaluate-module-v1-disappoint"), //差评编辑中的  评价回复      很抱歉，让您失望了！
      noVisible: false, //差评编辑 弹框的显示隐藏
      noIsEdit: false, //差评 悬浮显示编辑 小手
      noDetailId: "", //差评 详情id
      noisCheckedIconOne: true, //差评 选中第一种样式类型
      noisCheckedIconTwo: false, //差评 选中第二种样式类型
      noisCheckedIconThree: false, //差评 选中第三种样式类型
      isEvTypeOne: true, //评价表情  第一种类型
      isEvTypeTwo: false, //评价表情  第二种类型
      isEvTypeThree: false, //评价表情  第三种类型
      chooseEvType: "", //所选的评价表情类型
      evLinkOptions: [
        {
          id: "evLink001",
          value: this.$i18n.t("font-evaluate-module-v1-case-closed"), //仅当个案关闭时
        },
        {
          id: "evLink002",
          value: this.$i18n.t("font-evaluate-module-v1-cancle-reply"), //取消自动回复
        },
      ],
      evLinkValue: "", //添加评价链接所选内容
      isCaseClosed: false, //仅当个案关闭时 自定义模板显示
      evCaseOptions: [
        {
          id: "evCase001",
          value: this.$i18n.t("font-evaluate-module-v1-not-report"), //不重新打开
        },
        {
          id: "evCase002",
          value: this.$i18n.t("font-evaluate-module-v1-bad-review "), //当评价类型是差评时
        },
        {
          id: "evCase003",
          value: this.$i18n.t("font-evaluate-module-v1-fair-and-bad"), //当评价类型是一般和差评时
        },
      ],
      evCaseValue: "", //重新打开个案所选内容
      evCaseOwnerOptions: [
        {
          id: "evCaseOwner001",
          value: this.$i18n.t("font-evaluate-module-v1-not-inform"), //不通知
        },
        {
          id: "evCaseOwner002",
          value: this.$i18n.t("font-evaluate-module-v1-praise"), //评价类型是好评
        },
        {
          id: "evCaseOwner003",
          value: this.$i18n.t("font-evaluate-module-v1-evbad"), //评价类型是差评
        },
        {
          id: "evCaseOwner004",
          value: this.$i18n.t("font-evaluate-module-v1-general"), //评价类型是一般
        },
        {
          id: "evCaseOwner005",
          value: this.$i18n.t("font-evaluate-module-v1-evall"), //全部评价
        },
      ],
      evCaseOwnerValue: "", //通知个案所有者所选内容
      evSomeoneOptions: [], //差评要通知的人员
      evSomeoneValue: [], //收到3分以下评价时，通知以下人员 数组
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  watch: {
    evValue(newVal) {
      if (
        newVal === "evaluationsetting002" ||
        newVal === "evaluationsetting003"
      ) {
        this.isshowEmail = false;
      } else {
        this.isshowEmail = true;
      }
    },
  },
  methods: {
    // 初始化设置
    init() {
      // 评价设置 默认 邮件评价设置
      this.evValue = this.evOptions[0].id;
      // 评价设置页，查询评价设置和评价详情
      this.getEvaluationSetting();
      // 收到3分以下评价时，通知人员的全部数据
      this.getLookupData();
    },
    // 鼠标悬浮 评价得分提示的展示和消失
    showSuggest() {
      this.isshowSugg = true;
    },
    closeSuggest() {
      this.isshowSugg = false;
    },
    // 收到3分以下评价时，通知人员的全部数据
    async getLookupData() {
      let op = {
        fieldId: "",
        prefix: "005",
        objId: "user",
        modelid: "",
        objconid: "",
        isSLookup: "false",
        searchKeyWord: "",
        page: 1,
        pageSize: 500,
        selectedValue: "",
        searchConditions: "{}",
        filterConditionVals: '[""]',
        isKnowledge: "",
        projectId: "",
      };
      let res = await evaluationApi.getLookupData(op);
      this.evSomeoneOptions = res.data.dataList;
    },
    // 评价设置页，查询评价设置和评价详情
    async getEvaluationSetting() {
      this.isForbidden = true;
      let params = {
        id: this.evValue,
      };
      let res = await evaluationApi.getEvaluationSetting(params);
      this.evaluationSetData = res.data;
      // 评价问题
      this.evProblemText = this.evaluationSetData.evaluationquestion;
      // 添加评价链接
      this.evLinkValue = this.evaluationSetData.addevaluationurl;
      this.isCaseClosed =
        this.evaluationSetData.addevaluationurl ===
        this.$i18n.t("font-evaluate-module-v1-case-closed") //仅当个案关闭时
          ? true
          : false;
      // 重新打开个案
      this.evCaseValue = this.evaluationSetData.restartcase;
      // 通知个案所有者
      this.evCaseOwnerValue = this.evaluationSetData.noticecaseowner;
      // 收到3分以下评价时，通知的人员
      this.evSomeoneValue =
        this.evaluationSetData.noticewhennegative === null
          ? this.evaluationSetData.noticewhennegative
          : this.evaluationSetData.noticewhennegative.split(",");
      // 好 中 差评 数据回显
      this.evDetailArray = this.evaluationSetData.detail;
      this.evDetailArray.forEach((item) => {
        // 评价 表情类型 的选择
        this.chooseEvType = item.emojitype;
        if (this.chooseEvType === "1") {
          // 外部表情
          this.isEvTypeOne = true;
          this.isEvTypeTwo = false;
          this.isEvTypeThree = false;
          // 编辑内部表情
          // 好
          this.goodisCheckedIconOne = true;
          this.goodisCheckedIconTwo = false;
          this.goodisCheckedIconThree = false;
          // 中
          this.centerisCheckedIconOne = true;
          this.centerisCheckedIconTwo = false;
          this.centerisCheckedIconThree = false;
          // 差
          this.noisCheckedIconOne = true;
          this.noisCheckedIconTwo = false;
          this.noisCheckedIconThree = false;
        } else if (this.chooseEvType === "2") {
          // 外部表情
          this.isEvTypeOne = false;
          this.isEvTypeTwo = true;
          this.isEvTypeThree = false;
          // 编辑内部表情
          // 好
          this.goodisCheckedIconOne = false;
          this.goodisCheckedIconTwo = true;
          this.goodisCheckedIconThree = false;
          // 中
          this.centerisCheckedIconOne = false;
          this.centerisCheckedIconTwo = true;
          this.centerisCheckedIconThree = false;
          // 差
          this.noisCheckedIconOne = false;
          this.noisCheckedIconTwo = true;
          this.noisCheckedIconThree = false;
        } else {
          // 外部表情
          this.isEvTypeOne = false;
          this.isEvTypeTwo = false;
          this.isEvTypeThree = true;
          // 编辑内部表情
          // 好
          this.goodisCheckedIconOne = false;
          this.goodisCheckedIconTwo = false;
          this.goodisCheckedIconThree = true;
          // 中
          this.centerisCheckedIconOne = false;
          this.centerisCheckedIconTwo = false;
          this.centerisCheckedIconThree = true;
          // 差
          this.noisCheckedIconOne = false;
          this.noisCheckedIconTwo = false;
          this.noisCheckedIconThree = true;
        }
        // 好评详情
        if (
          item.id === "evaluationdetail0001" ||
          item.id === "evaluationdetail0004" ||
          item.id === "evaluationdetail0007"
        ) {
          // 表面详情
          this.evGoodValue = item.evaluations; //评价文本
          this.goodValue = item.reply; //评价后的回复文本
          // 编辑内部详情
          this.evGoodText = item.evaluations;
          this.evGoodReplay = item.reply;
          // item.status
        } // 中评详情
        else if (
          item.id === "evaluationdetail0002" ||
          item.id === "evaluationdetail0005" ||
          item.id === "evaluationdetail0008"
        ) {
          // 表面详情
          this.evCenterValue = item.evaluations; //评价文本
          this.centerValue = item.reply; //评价后的回复文本
          // 编辑内部详情
          this.evCenterText = item.evaluations;
          this.evCenterReplay = item.reply;

          this.isForbidden = item.status === "enable" ? true : false;
          // 禁用后 中评 显示启用按钮  隐藏编辑和禁用按钮
          this.centerishowBtn = this.isForbidden === false ? false : true;
        } // 差评详情
        else {
          // 表面详情
          this.evNoValue = item.evaluations; //评价文本
          this.noValue = item.reply; //评价后的回复文本
          // 编辑内部详情
          this.evNoText = item.evaluations;
          this.evNoReplay = item.reply;
        }
      });
    },
    // 切换评价设置
    switchSet(setdata) {
      this.evValue = setdata;
      // 切换设置 重新调取接口
      this.getEvaluationSetting();
    },
    // 自定义模板的 展示和隐藏
    caseOpenMethod(casedata) {
      // //仅当个案关闭时
      if (casedata === this.$i18n.t("font-evaluate-module-v1-case-closed")) {
        this.isCaseClosed = true;
      } else {
        this.isCaseClosed = false;
      }
    },

    // 跳转邮件模板
    goToEmail() {
      this.evToEmail = true;
      this.$refs.evEmail.init();
    },
    goBackToEv() {
      this.evToEmail = false;
    },

    //  好评好评好评好评好评好评好评好评好评好评
    // 好评编辑小笔 鼠标移入 显示
    goodMouseover() {
      this.goodIsEdit = true;
    },
    // 好评编辑小笔 鼠标移出 隐藏
    goodMouseout() {
      this.goodIsEdit = false;
    },
    // 编辑弹框 显示和隐藏
    goodEditMethod() {
      this.goodVisible = true;
      this.centerVisible = false;
      this.noVisible = false;
    },
    // 评价样式图标类型选择  (笑脸,小手)
    goodimageOneMethod() {
      this.goodisCheckedIconOne = true;
      this.goodisCheckedIconTwo = false;
      this.goodisCheckedIconThree = false;
    },
    goodimageTwoMethod() {
      this.goodisCheckedIconOne = false;
      this.goodisCheckedIconTwo = true;
      this.goodisCheckedIconThree = false;
    },
    goodimageThreeMethod() {
      this.goodisCheckedIconOne = false;
      this.goodisCheckedIconTwo = false;
      this.goodisCheckedIconThree = true;
    },
    // 好评编辑框 取消
    goodcancleBtn() {
      this.goodVisible = false;
    },
    // 好评编辑框 保存
    async goodsaveMeth() {
      // 判断 好评详情id
      if (this.evValue === "evaluationsetting001") {
        this.goodDetailId = "evaluationdetail0001";
      } else if (this.evValue === "evaluationsetting002") {
        this.goodDetailId = "evaluationdetail0004";
      } else {
        this.goodDetailId = "evaluationdetail0007";
      }
      // 判断 好评表情类型选定
      if (this.goodisCheckedIconOne === true) {
        this.chooseEvType = "1";
      } else if (this.goodisCheckedIconTwo === true) {
        this.chooseEvType = "2";
      } else {
        this.chooseEvType = "3";
      }
      let params = {
        settingid: this.evValue,
        id: this.goodDetailId,
        emojitype: this.chooseEvType,
        evaluations: this.evGoodText,
        reply: this.evGoodReplay,
      };
      await evaluationApi.saveEvaluationDetail(params);
      this.getEvaluationSetting();
      this.goodVisible = false;
    },

    //中评中评中评中评中评中评中评中评中评中评中评
    // 中评编辑小笔 鼠标移入 显示
    centerMouseover() {
      this.centerIsEdit = true;
    },
    // 中评编辑小笔 鼠标移出 隐藏
    centerMouseout() {
      this.centerIsEdit = false;
    },
    // 编辑弹框 显示和隐藏
    centerEditMethod() {
      this.centerVisible = true;
      this.goodVisible = false;
      this.noVisible = false;
    },
    // 评价样式图标类型选择  (笑脸,小手)
    centerimageOneMethod() {
      this.centerisCheckedIconOne = true;
      this.centerisCheckedIconTwo = false;
      this.centerisCheckedIconThree = false;
    },
    centerimageTwoMethod() {
      this.centerisCheckedIconOne = false;
      this.centerisCheckedIconTwo = true;
      this.centerisCheckedIconThree = false;
    },
    centerimageThreeMethod() {
      this.centerisCheckedIconOne = false;
      this.centerisCheckedIconTwo = false;
      this.centerisCheckedIconThree = true;
    },
    // 禁用
    centerforbiddenMethod() {
      this.deleteRuleTo = true;
      // 点击禁用按钮  三种评价的弹框都消失
      this.noVisible = false;
      this.goodVisible = false;
      this.centerVisible = false;
    },
    // 关闭禁用弹窗
    handleClose() {
      this.deleteRuleTo = false;
    },
    // 取消禁用
    concelForbidden() {
      this.deleteRuleTo = false;
    },
    // 确认禁用
    async confirmForbidden() {
      if (this.evValue === "evaluationsetting001") {
        this.centerDetailId = "evaluationdetail0002";
      } else if (this.evValue === "evaluationsetting002") {
        this.centerDetailId = "evaluationdetail0005";
      } else {
        this.centerDetailId = "evaluationdetail0008";
      }
      let params = {
        status: "disable",
        id: this.centerDetailId,
      };
      await evaluationApi.enableModerate(params);
      // 重新调用回显接口
      this.getEvaluationSetting();
      this.deleteRuleTo = false;
      this.$message({
        message: this.$i18n.t("font-evaluate-module-v1-disabled-successfully"), //评价选项'一般'禁用成功
        type: "success",
      });
    },
    // 启用
    async centerstartMethod() {
      if (this.evValue === "evaluationsetting001") {
        this.centerDetailId = "evaluationdetail0002";
      } else if (this.evValue === "evaluationsetting002") {
        this.centerDetailId = "evaluationdetail0005";
      } else {
        this.centerDetailId = "evaluationdetail0008";
      }
      let params = {
        status: "enable",
        id: this.centerDetailId,
      };
      await evaluationApi.enableModerate(params);
      // 重新调用回显接口
      this.getEvaluationSetting();
      this.$message({
        message: this.$i18n.t("font-evaluate-module-v1-enabled-successfully"), //评价选项'一般'启用成功
        type: "success",
      });
    },

    // 中评编辑框 取消
    centercancleBtn() {
      this.centerVisible = false;
    },
    // 中评编辑框 保存
    async centersaveMeth() {
      // 判断 中评详情id
      if (this.evValue === "evaluationsetting001") {
        this.centerDetailId = "evaluationdetail0002";
      } else if (this.evValue === "evaluationsetting002") {
        this.centerDetailId = "evaluationdetail0005";
      } else {
        this.centerDetailId = "evaluationdetail0008";
      }
      // 判断 中评表情类型选定
      if (this.centerisCheckedIconOne === true) {
        this.chooseEvType = "1";
      } else if (this.centerisCheckedIconTwo === true) {
        this.chooseEvType = "2";
      } else {
        this.chooseEvType = "3";
      }
      let params = {
        settingid: this.evValue,
        id: this.centerDetailId,
        emojitype: this.chooseEvType,
        evaluations: this.evCenterText,
        reply: this.evCenterReplay,
      };
      await evaluationApi.saveEvaluationDetail(params);
      this.getEvaluationSetting();
      this.centerVisible = false;
    },

    // 差评差评差评差评差评差评差评差评差评差评
    // 差评编辑小笔 鼠标移入 显示
    noMouseover() {
      this.noIsEdit = true;
    },
    // 差评编辑小笔 鼠标移出 隐藏
    noMouseout() {
      this.noIsEdit = false;
      // this.goodVisible = false
    },
    // 编辑弹框 显示和隐藏
    noEditMethod() {
      this.noVisible = true;
      this.goodVisible = false;
      this.centerVisible = false;
    },
    // 评价样式图标类型选择  (笑脸,小手)
    noimageOneMethod() {
      this.noisCheckedIconOne = true;
      this.noisCheckedIconTwo = false;
      this.noisCheckedIconThree = false;
    },
    noimageTwoMethod() {
      this.noisCheckedIconOne = false;
      this.noisCheckedIconTwo = true;
      this.noisCheckedIconThree = false;
    },
    noimageThreeMethod() {
      this.noisCheckedIconOne = false;
      this.noisCheckedIconTwo = false;
      this.noisCheckedIconThree = true;
    },
    // 差评编辑框 取消
    nocancleBtn() {
      this.noVisible = false;
    },
    // 差评编辑框 保存
    async nosaveMeth() {
      // 判断 差评详情id
      if (this.evValue === "evaluationsetting001") {
        this.noDetailId = "evaluationdetail0003";
      } else if (this.evValue === "evaluationsetting002") {
        this.noDetailId = "evaluationdetail0006";
      } else {
        this.noDetailId = "evaluationdetail0009";
      }
      // 判断 差评表情类型选定
      if (this.noisCheckedIconOne === true) {
        this.chooseEvType = "1";
      } else if (this.noisCheckedIconTwo === true) {
        this.chooseEvType = "2";
      } else {
        this.chooseEvType = "3";
      }
      let params = {
        settingid: this.evValue,
        id: this.noDetailId,
        emojitype: this.chooseEvType,
        evaluations: this.evNoText,
        reply: this.evNoReplay,
      };
      await evaluationApi.saveEvaluationDetail(params);
      this.getEvaluationSetting();
      this.noVisible = false;
    },

    // 评价设置页的 取消
    cancleBtn() {
      this.$router.push({
        path: "/systemSettings/setHomePage",
      });
    },
    // 评价设置页的 保存
    async saveMeth() {
      let params = {
        id: this.evValue,
        evaluationquestion: this.evProblemText,
        addevaluationurl: this.evLinkValue,
        restartcase: this.evCaseValue,
        noticecaseowner: this.evCaseOwnerValue,
        noticewhennegative: this.evSomeoneValue.toString(),
        ievaluationtyped: "",
      };
      await evaluationApi.saveEvaluationSetting(params);
      this.$router.push({
        path: "/systemSettings/setHomePage",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.evaluationBox {
  width: 100%;
  z-index: 100;
  .evtittle {
    width: 100%;
    padding: 20px;
    span {
      display: inline-block;
      min-width: 68px;
      height: 22px;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
    }
    .el-select {
      width: 488px;
      height: 36px;
      background: #ffffff;
      border-radius: 4px;
      margin-left: 15px;
      ::v-deep .el-input__inner {
        height: 36px;
        line-height: 36px;
      }
    }
  }
  .evContent {
    width: 100%;
    padding: 28px 0 28px 0;
    border-top: 1px solid #dedcda;
    border-bottom: 1px solid #dedcda;
    ::v-deep .el-textarea__inner {
      border-left: none;
      border-top: none;
      border-right: none;
      border-radius: 0;
      padding: 5px 0;
    }
    // 评价问题
    .evProblem {
      display: flex;
      align-items: center;
      .problemText {
        width: 157px;
        text-align: right;
        font-size: 14px;
        color: #666666;
      }
      .el-textarea {
        width: 50%;
        margin-left: 12px;
        font-size: 14px;
        color: #333333;
      }
      ::v-deep .el-textarea__inner {
        width: 420px;
        height: 22px;
      }
    }
    // 评价详情
    .evDetailBox {
      width: 100%;
      display: flex;
      margin-top: 28px;
      .detailText {
        width: 161px;
        font-size: 14px;
        text-align: right;
        padding-right: 5px;
        color: #666666;
      }
      .evDetail {
        // width: 70%;
        display: flex;
        // justify-content: space-between;
        margin-left: 9px;
        //  好 中 差评 共用样式
        .reputation {
          position: relative;
          width: 224px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #dedcda;
          text-align: center;
          .reputationTop {
            padding: 20px 28px 16px 28px;
            border-bottom: 1px solid #dedcda;
            .icon {
              width: 44px;
              height: 44px;
            }
            .pingjia {
              font-size: 14px;
              color: #666666;
              margin-top: 9px;
            }
            // 编辑弹框
            .allEditDetail {
              position: absolute;
              top: 32px;
              right: 0;
              width: 330px;
              background: #ffffff;
              box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.4);
              border-radius: 5px;
              border: 1px solid #dedcda;
              font-size: 14px;
              color: #666666;
              padding: 20px;
              z-index: 1000;
              div {
                text-align: left;
              }
              // 三角
              .triangle {
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 15px solid transparent;
                position: absolute;
                top: -7px;
                right: 9px;
                border-style: solid;
                border-color: #ffffff #ffffff transparent transparent;
                border-width: 8px;
                transform: rotate(-45deg);
                box-shadow: 2px -2px 2px #ccc;
              }
              .chooseImage {
                .threeImage {
                  display: flex;
                  margin: 15px 0;
                  .imageOne,
                  .imageTwo,
                  .imageThree {
                    position: relative;
                    width: 55px;
                    height: 40px;
                    text-align: center;
                    cursor: pointer;
                    .icon {
                      width: 37px;
                      height: 37px;
                    }
                  }
                  .imageTwo,
                  .imageThree {
                    margin-left: 10px;
                  }
                }
              }
              .appraise {
                margin: 20px 0;
                div {
                  margin: 5px 0;
                }
                div:nth-of-type(3) {
                  margin: 11px 0 0;
                }
              }
              .allEditBtn {
                .el-button {
                  min-width: 64px;
                  height: 30px;
                  border-radius: 3px;
                  line-height: 0;
                  padding: 12px 17px !important;
                }
                .allSaveBtn:hover {
                  background: #006dcc !important;
                }
              }
            }
          }

          .reputationText {
            padding: 15px 28px 20px 28px;
            p {
              font-size: 14px;
              color: #666666;
            }
            .reputationValue {
              font-size: 14px;
              color: #333333;
              margin-top: 8px;
            }
          }
        }
        // 评分标准
        .suggests {
          height: 80px;
          position: relative;
          cursor: pointer;
          // border: solid 1px;
          .icon {
            display: inline-block;
            width: 20px;
            height: 20px;
          }
          .suggContent {
            position: absolute;
            top: -31px;
            left: 32px;
            width: 160px;
            height: 80px;
            padding-left: 15px;
            background: linear-gradient(135deg, #666666 0%, #2e2e2e 100%);
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.39);
            border-radius: 5px;
            div {
              padding: 7px 0 1px 0;
              font-size: 12px;
              color: #ffffff;
              text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.39);
            }
          }
          .suggtriangle {
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 15px solid transparent;
            position: absolute;
            top: 5px;
            left: 28px;
            border-style: solid;
            border-color: #666666 #666666 transparent transparent;
            border-width: 4px;
            transform: rotate(-135deg);
            // box-shadow: 2px -2px 2px #666666;
          }
        }
        // 好评
        .goodReputation {
          position: relative;
          margin-right: 28px;
          .editBtnGood {
            width: 20px;
            height: 20px;
            background-color: #dedcda;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            .icon {
              display: inline-block;
              width: 20px;
              height: 20px;
            }
          }
        }
        // 禁用的 中评
        .forbiddenClass {
          position: relative;
          background: #ffffff;
          border-radius: 4px;
          opacity: 0.4;
          border: 1px solid #dedcda;
          margin-right: 28px;
          .editAndForbidden {
            position: absolute;
            top: 0;
            right: 0;
            // border: solid 1px;
            display: flex;
            .editBtnCenter {
              width: 20px;
              height: 20px;
              background-color: #dedcda;
              cursor: pointer;
              .icon {
                display: inline-block;
                width: 20px;
                height: 20px;
              }
            }
            .forbiddenBtnCenter {
              width: 20px;
              height: 20px;
              background-color: #dedcda;
              cursor: pointer;
              .icon {
                display: inline-block;
                width: 20px;
                height: 20px;
              }
            }
            .startBtnCenter {
              width: 20px;
              height: 20px;
              background-color: #dedcda;
              cursor: pointer;
              .icon {
                display: inline-block;
                width: 20px;
                height: 20px;
              }
            }
          }
        }
        // 中评
        .centerReputation {
          position: relative;
          margin-right: 28px;
          .editAndForbidden {
            position: absolute;
            top: 0;
            right: 0;
            // border: solid 1px;
            display: flex;
            .editBtnCenter {
              width: 20px;
              height: 20px;
              background-color: #dedcda;
              cursor: pointer;
              .icon {
                display: inline-block;
                width: 20px;
                height: 20px;
              }
            }
            .forbiddenBtnCenter {
              width: 20px;
              height: 20px;
              background-color: #dedcda;
              cursor: pointer;
              .icon {
                display: inline-block;
                width: 20px;
                height: 20px;
              }
            }
            .startBtnCenter {
              width: 20px;
              height: 20px;
              background-color: #dedcda;
              cursor: pointer;
              .icon {
                display: inline-block;
                width: 20px;
                height: 20px;
              }
            }
          }
        }
        // 差评
        .noReputation {
          position: relative;
          margin-right: 20px;
          .editBtnNo {
            width: 20px;
            height: 20px;
            background-color: #dedcda;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            .icon {
              display: inline-block;
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
  .evBottom {
    padding: 0 0 80px 20px;
    .chooseBox {
      display: flex;
      align-items: center;
      margin: 28px 0 0 0;
      .el-select {
        width: 420px;
        background: #ffffff;
        border-radius: 4px;
        margin-left: 15px;
        ::v-deep .el-input__inner {
          height: 36px;
          line-height: 36px;
        }
      }
      span {
        display: inline-block;
        width: 135px;
        text-align: right;
      }
      .casebox {
        width: 135px;
        cursor: pointer;
        text-align: left;
        margin-left: 15px;
      }
    }
  }
  .operationBox {
    padding: 50px 0 30px 0;
    .el-button {
      min-width: 64px;
      height: 34px;
      border-radius: 3px;
      padding: 9px 18px !important;
      font-size: 12px !important;
    }
    .cancleBtn {
      margin-left: 49%;
    }
    .saveBtn:hover {
      background: #006dcc;
    }
  }

  //
  ::v-deep .el-dialog {
    width: 552px;
  }
  .dialog-body {
    display: flex;
    align-items: center;
    padding: 10px 0;
    color: #080707;
    .icon {
      display: inline-block;
      margin-right: 10px;
    }
  }
}
</style>

