import axios from "@/config/httpConfig";

// 评价设置页，查询评价设置和评价详情
export function getEvaluationSetting(data) {
  return axios.post("/ServiceEvaluation/getEvaluationSetting", data);
}

//收到3分以下评价时，通知以下人员接口
export function getLookupData(data) {
  return axios.post("/lookup/getLookupData", data);
}

// 保存评价设置详情
export function saveEvaluationDetail(data) {
  return axios.post("/ServiceEvaluation/saveEvaluationDetail", data);
}

//启用、禁用一般评价
export function enableModerate(data) {
  return axios.post("/ServiceEvaluation/enableModerate", data);
}

//保存评价设置
export function saveEvaluationSetting(data) {
  return axios.post("/ServiceEvaluation/saveEvaluationSetting", data);
}

//联系人满意度评价相关列表返回评价
export function getContactSatisfactionEvaluation(data){
  return axios.post('/ServiceEvaluation/getContactSatisfactionEvaluation',data)
}

//客户满意度评价相关列表返回评价
export function getAccountSatisfactionEvaluation(data){
  return axios.post('/ServiceEvaluation/getAccountSatisfactionEvaluation',data)
}



